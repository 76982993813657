import React, {FC, ReactNode, useState} from 'react'
import {ArrowRoundedDown12x7Svg} from '../../../../../svg'
import cls from "./customMobileMenu.module.scss"
import Link from "next/link";
import {ICategory} from "../../../../../types/productPageTypes";

type firstElemType = {
    children: Array<any>;
    label: string;
    frontLink?: string;
    new_tab?: number;
    name?: string;
    id?: number;
}

interface ICustomMobileLinks {
    level?: number;
    links: Array<firstElemType> | Array<ICategory>;
    onItemClick: (item?: { data?: { type: string; locale: string; }; type?: string; }) => void;
    labelRender: string;
    svgRender: ReactNode
}

interface ICustomMobileLink extends ICustomMobileLinks {
    item: any,
    index: number
}


const CustomMobileLink: FC<ICustomMobileLink> = ({
                                                     item,
                                                     index,
                                                     level = 0,
                                                     onItemClick,
                                                     labelRender,
                                                     svgRender
                                                 }) => {
    const {frontLink, target = {}, name, children, id, label = null} = item
    const [open, setOpen] = useState(level === 0)

    const arrow_subLinksHandle = (linkChildren: Array<ICategory>) => {
        return {
            "true": {
                arrow: <button aria-label="toggle menu" className={cls["mobile-links__item-toggle"]}>
                    <ArrowRoundedDown12x7Svg className={cls["mobile-links__item-arrow"]}/>
                </button>,
                subLinks: (
                    <div className={cls["mobile-links__item-sub-links"]}>
                        <CustomMobileLinks
                            links={linkChildren}
                            level={level + 1}
                            onItemClick={onItemClick}
                            labelRender={labelRender}
                            svgRender={svgRender}
                        />
                    </div>
                )
            },
            "false": {
                arrow: null,
                subLinks: null
            }
        }
    }

    const firstButtonCat = {
        "true": <div style={{height: "0"}} className={cls["link-label"]}></div>,
        "false": <Link href={frontLink as string}>
            <a
                rel="noopener noreferrer"
                {...target}
                className={cls["mobile-links__item-link"]}
                onClick={() => onItemClick()}
            >
                <button className={cls["mobile-links__item-link"]} onClick={() => onItemClick()}>
                    {name}{' '}
                </button>
            </a>
        </Link>
    }

    const firstLink = {
        "true": {
            className: cls["mobile-links__item--open"],
            element: null
        },
        "false": {
            className: "",
            element: (
                <div style={label === "Cat" ? {height: "0", padding: "0"} : {}} className={cls["mobile-links__item-title"]} onClick={() => setOpen(prevOpen => !prevOpen)}>
                    {firstButtonCat[`${label === "Cat"}`]}
                    {open ? null : arrow_subLinksHandle(children)[`${children?.length > 0}`].arrow}
                </div>
            )
        }
    }

    return <li key={index}>
        <div className={`${cls["mobile-links__item"]} ${firstLink[`${id === 1 || open}`].className} `}>
            {firstLink[`${id === 1}`].element}
            {arrow_subLinksHandle(children)[`${children?.length > 0}`].subLinks}
        </div>
    </li>
}

const CustomMobileLinks: FC<ICustomMobileLinks> = (props) => {
    const {
        links,
        level = 0,
    } = props

    return (
        <ul className={`${cls["mobile-links"]} ${cls[`mobile-links--level--${level}`]}`}>
            {links?.map((item, index) => {
                return <CustomMobileLink item={item} index={index} key={index} {...props}/>
            })}
        </ul>
    )
}

export default CustomMobileLinks;
